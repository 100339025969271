import React, {useState} from 'react';
import classNames from "classnames";
import { useResource } from 'retil';
import { Link } from 'react-navi';
import {Entries, Photo} from '@2022-mizucon-web/components/src/components';
import {Entry} from "@2022-mizucon-web/components/src/types";
import { StepBox } from '@2022-mizucon-web/components/src/components/StepBox';
import { Hero, Footer } from '../../components';
import {entryList} from "../../resources";
import {EntryCard} from "../../components/card";
import { Twitter } from '../../components/twitter';
import TweetEmbed from 'react-tweet-embed';

export function TopApp() {
  const [entries] = useResource(entryList);
  const [openContent1, setOpenContent1] = useState(false);
  const [openContent2, setOpenContent2] = useState(false);
  const [openContent3, setOpenContent3] = useState(false);
  const [openContent4, setOpenContent4] = useState(false);
  return (
    <main className="TopApp">
      <Hero />
      <section id="about" className="section as-about-section">
        <div className="container" style={{ maxWidth: 360 }}>
          <figure className="image is-margin-bottom-3">
            <img src="/img/theme.jpg" width={260} />
          </figure>
        </div>
        <div className="container" style={{ maxWidth: 550 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
          </div>
          <p
            className="notification is-padding-top-1-5 is-padding-bottom-1-5 is-padding-left-1-5 is-padding-right-1-5 is-margin-bottom-3 is-size-7-mobile"
            style={{ margin: '0 auto' }}
          >
            水コンはお茶の水女子大学の徽音祭で開催される、選出されたファイナリストが様々な角度から自分を魅せるコンテストです。
            <br />
            <br />
            『ULU』にはハワイ語で<strong className="has-text-marker">「成長する」「育つ」「広がる」「繁栄する」「神の持ち物」</strong>という意味があります。
            <br />
            <br />
            今年の水コンはファイナリストに対し
            <div className="content">
              <div className="is-flex is-align-items-center is-flex-direction-column">
                <ul className="is-flex is-flex-direction-column">
                  <li>"水コン"を通して自分の将来に何を見るか</li>
                  <li>"水コン"を人生のどのような通過点として捉えるのか</li>
                  <li>"水コン"をどのように成長の糧とするのか</li>
                </ul>
              </div>
            </div>
            を問うことで、<strong className="has-text-marker">ファイナリストたちが”水コン”を通して成長していく姿</strong>に焦点を当てます。
          </p>
        </div>
      </section>
      {/* FINALIST */}
      <section id="finalist" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Finalist</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries state={entries} noshuffle>
              {(list: Entry[]) =>
                  list.map((item: Entry) => (
                      <div
                          className={classNames('column is-4-tablet is-6-mobile')}
                          key={item.entry_id}
                      >
                        <EntryCard onlive={false} entry={item} href={`/profiles/${item.entry_id}`} />
                      </div>
                  ))
              }
            </Entries>
          </div>
          <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-1">
            <button className="button is-rounded" onClick={() => setOpenContent1(true)} style={{ fontSize: "min(1rem,3vw)",backgroundColor: "#97D06A", color:"#ffffff" }}>水コン2022エントリーNo.4門上華子 活動辞退のお知らせ</button>
          </div>
        </div>
      </section>
      {/* CONTENTS */}
      <section id="contents" className="section">
        <div className="container" style={{ maxWidth: 520 }}>
          <div className="is-margin-bottom-1-5">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
          </div>
          <p
            className="notification has-text-centered has-background-white is-size-7-mobile is-margin-bottom-1-5"
            style={{ margin: '0 auto' }}
          >
            水コン2022では、7～11月にかけて、5つの企画を実施します。
          </p>
          <div className="columns is-centered is-multiline">
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step1.png"
                alt={'Twitter自己紹介企画'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">1</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「Twitter自己紹介企画」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">
                  140字・写真4枚のみで自己紹介を兼ねた自己PRをしてもらいます。
                  <br />
                  定められた期間中の「いいね」数を競います。
                </p>
                {/* <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-1">
                  <button className="button is-rounded" onClick={() => setOpenContent1(true)}>企画レポート</button>
                </div> */}
              </StepBox>
            </div>
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step2.png"
                alt={'テーマに沿ったコンテンツ紹介'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">2</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「テーマに沿ったコンテンツ紹介」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">
                運営が提示するテーマに合わせて、ファイナリスト各自が書籍、音楽、映像作品などから作品を1つ選んで紹介してもらいます。
                </p>
                {/* <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-1">
                  <button className="button is-rounded" onClick={() => setOpenContent2(true)}>企画レポート</button>
                </div> */}
              </StepBox>
            </div>
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step3.png"
                alt={'プレゼン企画'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">3</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「プレゼン企画」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">
                自分が掲げた成長目標に関して水コンをどのように利用するのか、人生の通過点として水コンをどのような成長の糧とするのかをプレゼンしてもらいます。
                </p>
                {/* <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-1">
                  <button className="button is-rounded" onClick={() => setOpenContent3(true)}>企画レポート</button>
                </div> */}
              </StepBox>
            </div>
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step2.png"
                alt={'匿名レポート企画'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">4</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「匿名レポート企画」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">
                  なんらかの社会問題に対して意見を発信してもらいます。
                  <br />
                  顔の見えないものを評価軸に入れることで「容姿のみのコンテスト」と言う一面を排除します。
                </p>
                {/* <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-1">
                  <button className="button is-rounded" onClick={() => setOpenContent4(true)}>投票はこちら</button>
                </div> */}
              </StepBox>
            </div>
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step2.png"
                alt={'自己PR'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">5</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「自己PR」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">徽音祭当日、ステージ上で自分の魅力を最大限表現してもらいます。</p>
              </StepBox>
            </div>
          </div>
        </div>
      </section>
      {/* EXHIBITION */}
      <section className="section">
        <div id="vote" className="container" style={{ maxWidth: 520 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">The Details of Voting</h2>
          </div>
          <figure className="image is-margin-bottom-4 is-margin-top-2" style={{ maxWidth: 480, margin: '0 auto' }}>
            <img src="/img/vote_description.jpg" />
          </figure>
          <div>
            <p className="is-size-7-mobile">
              水コン2022では次の4種類の投票を行います。
              <br />
              <br />
              ・Web投票
              <br />
              お披露目から徽音祭までの4ヶ月間に数回、投票期間を設けます。
              <br />
              期間中は1日1回どなたでも投票できます。
              <br />
              <br />
              ・お茶大生票
              <br />
              お茶大生のみが投票できます。
              <br />
              <br />
              ・当日票
              <br />
              徽音祭1日目に行う自己PRの後の最終投票です。どなたでも投票できます。
              <br />
              <br />
              ・企画票
              <br />
              SNSなどを用いて行うさまざまな企画ごとの投票です。
              <br />
              昨年は企画内で完結し、グランプリ決定には反映されませんでした。
              <br />
              水コン2022では、ファイナリストの努力を直接的に結果に反映させるため、また、より多くの人に企画へ興味を持ってもらうため、グランプリ決定に反映します。
              <br />
              <br />
              ※自己紹介企画につきましては、公正な順位付けができない事例が発生したため、最終的な順位に反映しません。
            </p>
          </div>
        </div>
      </section>
      {/* RESULTS */}
      {/* <section id="results" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Results</h2>
          </div>
          <p
              className="notification is-padding-top-1-5 is-padding-bottom-1-5 is-padding-left-1-5 is-padding-right-1-5 is-margin-bottom-3 is-size-7-mobile"
              style={{ margin: '0 auto', maxWidth: 520 }}
          >
            水コン2022は、11月14日に結果発表を行い、ファイナリスト全員に対する表彰を行いました。これは水コン2022の新しい取り組みの1つです。<br />
            徽音祭の開催にあたり、ご協力いただきました企業のみなさま、大学関係者のみなさまに心より御礼申し上げます。みなさまのおかげで、第72回徽音祭及び水コン2022はフィナーレを迎えることができました。今後とも徽音祭実行委員会及び水コンをどうぞよろしくお願いいたします。
          </p>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/1`}>
                  <Photo
                      src={`/img/entry/1/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        新進気鋭賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"関野 佳穏"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"文教育学部 \n言語文化学科"} {"1年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    新進気鋭とは、新たにその分野に現れ、意気込みが鋭く、将来有望なさま、また、そのような人のことを指す四字熟語です。 <br />
                    水コン 2021 で唯一の 1 年生であった関野さん。 その心意気は非常に鋭く、SNS 上での意見の発信や、ビブリオバトルをはじめとした企画ではその存在感を示しました。
                  </div>
                </Link>
              </div>
            </div>
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/2`}>
                  <Photo
                      src={`/img/entry/2/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        勇往邁進賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"堀田 明日香"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"生活科学部 \n人間生活学科"} {"3年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    勇往邁進とは、元気よく前進することを恐れることなく、自分の目的や目標に向かってひたすら前進することを意味する四字熟語です。<br />
                    自分の目標ややりたいことに対して真っ直ぐな姿勢で活動していた堀田さん。「自分」や「自分らしさ」というものを大切にしながら発信している姿が印象的でした。
                  </div>
                </Link>
              </div>
            </div>
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/3`}>
                  <Photo
                      src={`/img/entry/3/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-margin-bottom-0-5 is-warning is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        グランプリ
                      </p><br />
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        活気横溢賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"原 亜由子"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"文教育学部 \n芸術表現行動学科"} {"3年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    活気横溢とは、いきいきとした気分が溢れんばかりにみなぎっていることを意味する四字熟語です。<br />
                    学生数が少ないお茶大の中でも、特に人数が少ない舞踊学科からの挑戦だった原さん。得意で専攻であるダンス表現からはもちろん、SNSで行われた写真や文章の表現全てからもいきいきとした明るさが感じられました。
                  </div>
                </Link>
              </div>
            </div>
            <div
              className={classNames('column is-4-tablet is-6-mobile')}>
            <div className="entry-card">
              <Link href={`/profiles/4`}>
                <Photo
                    src={`/img/entry/4/result.jpg`}
                    alt={""}
                >
                  <figcaption className="entry-card-mask">
                    <figure className="image is-1by1">
                      <figcaption>
                        <p className="has-text-weight-bold">詳しく見る</p>
                      </figcaption>
                    </figure>
                  </figcaption>
                  <div className="entry-card-prize">
                    <p className="is-margin-bottom-0-5 is-warning is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                      準グランプリ
                    </p><br />
                    <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                      積土成山賞
                    </p>
                  </div>
                </Photo>
                <div className="entry-card-title">
                  <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"山内 一姫"}</p>
                  <p className="entry-card-title-university is-size-7-mobile">{"生活科学部 \n食物栄養学科"} {"4年"}</p>
                </div>
                <div className="is-size-7-mobile is-size-6">
                  積土成山とは、努力を積み重ねていけば、やがて大成することを意味する四字熟語です。<br />
                  山内さんは、「自分磨き投稿」やダンス全ジャンルに挑戦する「自己 PR チャレンジ」が印象的でした。常に努力を重ね、水コンで最初に掲げた山内さん自身の「努力したい」という目標を達成しようとする活動がとても印象的でした。
                </div>
              </Link>
            </div>
          </div>
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/5`}>
                  <Photo
                      src={`/img/entry/5/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        英明果敢賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"神野 莉子"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"理学部 \n情報科学科"} {"4年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    英明果敢とは、優れて賢い『英明』と、決断力に富み、物事を思い切ってする様子を意味する『果敢』を合わせた、才知に優れ、道理に明るく、思い切りのいいことを表す四字熟語です。<br />
                    多くの知識が問われるクイズというコンテンツをSNS上の独自企画やスペース機能を用いて行った神野さん。才知に優れた様々な思い切った活動が印象的でした。
                  </div>
                </Link>
              </div>
            </div>
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/6`}>
                  <Photo
                      src={`/img/entry/6/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        和風慶雲賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"漉野 ヨリ"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"文教育学部 \n言語文化学科"} {"4年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    和風慶雲とは、穏やかに吹くそよ風と、吉兆を示すめでたい雲であり、温厚で徳を備えた人格者を形容する四字熟語です。「#ヨリより」を用いて投稿された 「自分を愛すること」に対するご自身の考えを綴った文章は、水コン2022を見ていた自分に自信を持てずにいた人たちを励ますものだったでしょう。
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* OUTLINE */}
      <section className="section">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Outline</h2>
          </div>
          <div className="columns is-mobile is-multiline">
            <div className="column is-5">
              <p className="title is-6">主催団体名</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">お茶の水女子大学徽音祭実行委員会</p>
            </div>

            <div className="column is-5">
              <p className="title is-6">Twitter</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://twitter.com/mizucon_2022">@mizucon_2022</a>
                </span>
              </p>
            </div>
            <div className="column is-5">
              <p className="title is-6">Instagram</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://www.instagram.com/mizucon_2022">@mizucon_2022</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-1-5">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Twitter</h2>
          </div>
          <Twitter user="mizucon_2022" />
        </div>
      </section>
      <Footer />
      {/* <section className="as-fixed-footer">
        <div className="container">
          <Link href="/#finalist" className="is-flex is-flex-direction-column is-align-items-center has-text-centered has-text-white has-text-weight-bold">
              <p>『Web投票』期間中！</p>
              <p className="is-size-7">投票は各出場者のページから！</p>
          </Link>
        </div>
      </section> */}
      {/* <section className="as-fixed-footer">
        <div className="container">
          <a onClick={() => setOpenContent4(true)} className="is-flex is-flex-direction-column is-align-items-center has-text-centered has-text-white has-text-weight-bold">
              <p>『匿名レポート企画』投票期間中！</p>
              <p className="is-size-7">投票はこちらから！</p>
          </a>
        </div>
      </section> */}
      <div className={classNames("modal", {
        "is-active": openContent1
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{ width: "100vw", height:"100vh",margin:0,maxHeight:"100vh"}}>
        <div style={{ width: "100vw", height:"30vh", backgroundColor: "#97D06A", padding: "auto 1.5rem",display:"flex", alignItems:"center" }}>
            <div className="container" style={{ maxWidth: 550,padding: "0 1.5rem" }}>
                  <h2 className="has-text-futura-pt has-text-italic" style={{ fontSize:"min(24px,5vw)" }}>水コン2022エントリーNo.4門上華子<br></br>活動辞退のお知らせ</h2>
                  <p style={{ fontSize:"min(14px,2vw)" }}>2022.9.20</p>
                </div>
        </div>
        <div style={{ width: "100vw", minHeight:"70vh",backgroundColor: "#ffffff", paddingTop: 50, paddingLeft:"1.5rem", paddingRight:"1.5rem" }}>
          <div className="container" style={{ maxWidth: 550,padding: "0 1.5rem" }}>
                <p style={{ fontSize:"min(14px,3vw)" }}>
                　日頃より水コン2022を応援いただきありがとうございます。
                <br></br><br></br>
                　エントリーNo.4門上華子につきまして、本人より、体調が不安定で活動を続けることが難しくなってしまったため、辞退したいとの申し入れがあり、9月20日付けで水コン2022ファイナリストとしての活動を終了することになりました。
                <br></br><br></br>
                　今後の企画、SNS活動及び徽音祭当日の最終自己PRを楽しみにお待ちいただいておりました皆様には大変申し訳ございませんが、ご理解とご了承の程よろしくお願いいたします。
                <br></br>
                また、活動中応援いただいた皆様に感謝を申し上げます。
                <br></br><br></br>
                　今後とも水コン2022への応援をよろしくお願いいたします。
                </p>
          </div>
        </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent1(false)} />
      </div>

      {/* <div id="report" className={classNames("modal", {
        "is-active": openContent4
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{width: "100%", maxHeight: "100vh"}}>
          <section className="section is-padding-bottom-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
              </div>
              <p>
                レポート企画は、<strong className="has-text-marker">大学生活を通して関心が高まったSDGs17の目標</strong>を一つ選んで、ファイナリスト3名各自がレポートを匿名で公開します。
              </p>
            </div>

          </section>
          <section className="section">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
              </div>
              <div className="has-text-centered is-margin-bottom-3">
                <p className="is-size-5 has-text-weight-bold has-text-italic">レポートA</p>
                <TweetEmbed id="1585149224710184961" options={{lang: "ja"}} className="is-flex is-justify-content-center is-minheight-300px" />
                <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-6">
                  <a className="button is-rounded" href={`https://liff.line.me/1656170082-mjN5w2BK/vote/mizucon2022/N/1?liffId=1656170082-mjN5w2BK`}>レポートAに投票する</a>
                </div>
              </div>
              <div className="has-text-centered is-margin-bottom-3">
                <p className="is-size-5 has-text-weight-bold has-text-italic">レポートB</p>
                <TweetEmbed id="1585149229810556932" options={{lang: "ja"}} className="is-flex is-justify-content-center is-minheight-300px" />
                <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-6">
                  <a className="button is-rounded" href={`https://liff.line.me/1656170082-mjN5w2BK/vote/mizucon2022/N/2?liffId=1656170082-mjN5w2BK`}>レポートBに投票する</a>
                </div>
              </div>
              <div className="has-text-centered is-margin-bottom-3">
                <p className="is-size-5 has-text-weight-bold has-text-italic">レポートC</p>
                <TweetEmbed id="1585149229298851842" options={{lang: "ja"}} className="is-flex is-justify-content-center is-minheight-300px" />
                <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-6">
                  <a className="button is-rounded" href={`https://liff.line.me/1656170082-mjN5w2BK/vote/mizucon2022/N/3?liffId=1656170082-mjN5w2BK`}>レポートCに投票する</a>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0" />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent4(false)} />
      </div> */}

      {/* <div className={classNames("modal", {
        "is-active": openContent1
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{width: "100%", maxHeight: "100vh"}}>
          <section className="section is-padding-bottom-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
              </div>
              <p>
                自己紹介企画とは、<strong className="has-text-marker">ファイナリストが4枚の画像と140字で自分の魅力を伝える企画</strong>です。
                お披露目の7月12日から7月22日までの10日間に該当するツイートに寄せられた、いいね数を競いました。
              </p>
            </div>
          </section>
          <section className="section">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.1</p>
                <p>関野 佳穏</p>
                <TweetEmbed id="1414426004286672897" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.2</p>
                <p>堀田 明日香</p>
                <TweetEmbed id="1414429709836775426" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.3</p>
                <p>原 亜由子</p>
                <TweetEmbed id="1414433126684651522" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.4</p>
                <p>山内 一姫</p>
                <TweetEmbed id="1414426645163696131" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.5</p>
                <p>神野 莉子</p>
                <TweetEmbed id="1414425528644243458" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.6</p>
                <p>漉野 ヨリ</p>
                <TweetEmbed id="1414426446550867968" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Result</h2>
              </div>
              <p className="notification">
                １位 entry no.5 神野 莉子 525票<br />
                ２位 entry no.6 漉野 ヨリ 391票<br />
                ３位 entry no.3 原 亜由子 360票
              </p>
              <div className="has-text-centered is-margin-bottom-1-5">
                <TweetEmbed id="1418397439069884418" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0" />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent1(false)} />
      </div> */}
      {/* <div className={classNames("modal", {
        "is-active": openContent2
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{width: "100%", maxHeight: "100vh"}}>
          <section className="section is-padding-bottom-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
              </div>
              <p>
                お茶大紹介企画とは、運営が提示するテーマに沿って、ファイナリストが文章や写真、動画などをSNSに投稿し、<strong className="has-text-marker">お茶大の魅力を発信する企画です。</strong>
                ファイナリストの8月16日から8月21日までのSNS投稿に対するweb投票数を競いました。
              </p>
            </div>
          </section>
          <section className="section">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.1</p>
                <p>関野 佳穏</p>
                <TweetEmbed id="1427112680930770949" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428123011085787137" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.2</p>
                <p>堀田 明日香</p>
                <TweetEmbed id="1427110601990426624" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428219619437735938" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.3</p>
                <p>原 亜由子</p>
                <TweetEmbed id="1427584204372467722" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428678868286664708" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.4</p>
                <p>山内 一姫</p>
                <TweetEmbed id="1427456383553740826" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428559410662580229" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.5</p>
                <p>神野 莉子</p>
                <TweetEmbed id="1427827877093904387" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428996617932181504" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.6</p>
                <p>漉野 ヨリ</p>
                <TweetEmbed id="1427833901871861766" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428921951561469965" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Result</h2>
              </div>
              <p className="notification">
                １位 entry no.3　原亜由子<br />
                ２位 entry no.5　神野莉子<br />
                ３位 entry no.2　堀田明日香
              </p>
              <div className="has-text-centered is-margin-bottom-1-5">
                <TweetEmbed id="1433788203270488077" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0" />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent2(false)} />
      </div> */}
      {/* <div className={classNames("modal", {
        "is-active": openContent3
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{width: "100%", maxHeight: "100vh"}}>
          <section className="section is-padding-bottom-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
              </div>
              <p>
                水コン式ビブリオバトルとは、<strong className="has-text-marker">ファイナリスト6名が面白いと思った本を各自5分で紹介する企画</strong>です。発表後、「どの本が一番読みたくなったか」という基準で投票してもらい、その投票数を競いました。
              </p>
            </div>
          </section>
          <section className="section">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
              </div>
              <figure className="image is-16by9">
                <iframe className="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/6HhLWvlI0EU"
                        frameBorder="0" allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"

                />
              </figure>
            </div>
          </section>
          <section className="section is-padding-top-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Result</h2>
              </div>
              <p className="notification">
                １位 entry no.3　原亜由子<br />
                ２位 entry no.1　関野佳穏<br />
                ３位 entry no.5　神野莉子
              </p>
              <div className="has-text-centered is-margin-bottom-1-5">
                <TweetEmbed id="1444119874507137028" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0" />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent3(false)} />
      </div> */}
      {/* <div className={classNames("modal", {
        "is-active": openContent4
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{width: "100%", maxHeight: "100vh"}}>
          <section className="section is-padding-bottom-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
              </div>
              <p>
                レポート企画は、<strong className="has-text-marker">長期化するコロナ禍における「大学生と〇〇」</strong>というテーマで、ファイナリスト６名各自が〇〇の内容を自由に考え作成した2000字程度のレポートを匿名で公開する企画です。発表後、「新しい考え方や視点を得られた」「興味深い内容であると思った」「レポート内の構成や表現が優れていると感じた」という３つの基準で投票してもらい、その投票数を競いました。
              </p>
            </div>
          </section>
          <section className="section">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.1</p>
                <p>関野 佳穏</p>
                <TweetEmbed id="1451702324980707328" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.2</p>
                <p>堀田 明日香</p>
                <TweetEmbed id="1451702162308825093" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.3</p>
                <p>原 亜由子</p>
                <TweetEmbed id="1451702196400054272" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.4</p>
                <p>山内 一姫</p>
                <TweetEmbed id="1451702260820430854" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.5</p>
                <p>神野 莉子</p>
                <TweetEmbed id="1451702298107805702" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.6</p>
                <p>漉野 ヨリ</p>
                <TweetEmbed id="1451702225592483841" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Result</h2>
              </div>
              <p className="notification">
                １位 entry no.2 堀田明日香<br />
                ２位 entry no.3 原亜由子<br />
                ３位 entry no.1 関野佳穏
              </p>
              <div className="has-text-centered is-margin-bottom-1-5">
                <TweetEmbed id="1454661585390030850" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0" />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent4(false)} />
      </div> */}
    </main>
  );
}
