import React from 'react';
import { mount, compose, route, redirect, map } from 'navi';
import { Entry } from '@2022-mizucon-web/components/src/types';
import { ProfileApp } from './ProfileApp';
import { getResourceService } from 'retil';
import { entryList } from '../../resources';

const fromPairs = require('lodash.frompairs');

export default compose(
    map(async () => {
        const [source] = getResourceService(entryList);
        const list = await source.getData();
        return mount({
            '/': redirect('/profiles'),
            ...fromPairs(
                list.map((profile: Entry) => [
                    `/${profile.entry_id}`,
                    map(() => {
                        return route({ getView: () => <ProfileApp entry={profile} /> });
                    }),
                ]),
            ),
        });
    }),
);