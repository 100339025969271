import React from 'react';
import { InstagramButton, TwitterButton } from '@2022-mizucon-web/components/src/components';
import {Link} from "react-navi";

export function Hero() {
  return (
    <section className="hero is-large">
      <div className="hero-head">
        <nav className="navbar">
          <div className="container is-flex">
            <div className="navbar-item">
              <div className="field is-grouped is-grouped-multiline">
                <p className="control">
                  <Link href="/#about" className="button has-text-futura-pt is-text has-text-italic">
                    About
                  </Link>
                </p>
                <p className="control">
                  <Link href="/#finalist" className="button has-text-futura-pt is-text has-text-italic">
                    Finalist
                  </Link>
                </p>
                {/* <p className="control">
                  <Link href="/#contents" className="button has-text-futura-pt is-text has-text-italic">
                    Contents
                  </Link>
                </p> */}
                <p className="control">
                  <Link href="https://mizucon2021.mxcolle.com" className="button has-text-futura-pt is-text has-text-italic">
                    2021
                  </Link>
                </p>
                <p className="control">
                  <TwitterButton href="https://twitter.com/mizucon_2022" />
                </p>
                <p className="control">
                  <InstagramButton href="https://www.instagram.com/mizucon_2022" />
                </p>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="hero-body">
        <div className="container has-text-centered">
          <Link href="/">
            <h1 className="title has-text-white has-text-futura-pt has-text-italic has-text-weight-normal">水コン 2022</h1>
          </Link>
        </div>
      </div>
    </section>
  );
}

export function HeroHeader() {
  return <section className="hero as-header">
    <div className="hero-head">
      <nav className="navbar">
        <div className="container is-flex">
          <div className="navbar-item">
            <div className="field is-grouped is-grouped-multiline">
              <p className="control">
                <Link href="/#about" className="button has-text-futura-pt is-text has-text-italic">
                  About
                </Link>
              </p>
              <p className="control">
                <Link href="/#finalist" className="button has-text-futura-pt is-text has-text-italic">
                  Finalist
                </Link>
              </p>
              {/* <p className="control">
                <Link href="/#contents" className="button has-text-futura-pt is-text has-text-italic">
                  Contents
                </Link>
              </p> */}
              <p className="control">
                <Link href="https://mizucon2021.mxcolle.com" className="button has-text-futura-pt is-text has-text-italic">
                  2021
                </Link>
              </p>
              <p className="control">
                <TwitterButton href="https://twitter.com/mizucon_2022" />
              </p>
              <p className="control">
                <InstagramButton href="https://www.instagram.com/mizucon_2022" />
              </p>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div className="hero-body is-flex is-align-items-center">
      <div className="container has-text-centered ">
        <Link className="" href="/">
          <h1 className="title has-text-white has-text-futura-pt has-text-italic has-text-weight-normal">水コン 2022</h1>
        </Link>
      </div>
    </div>
  </section>
}