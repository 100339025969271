import { createDocumentResource, createQueryResource, list } from 'retil';
import { Entry } from '@2022-mizucon-web/components/src/types';

export const entry = createDocumentResource<Entry>('entry');
export const entryList = createQueryResource('entryList', {
    for: list(entry),
    load: async () => {
        return [
            {
                id: 1,
                entry_id: 1,
                block: 'A',
                stage: '1',
                name: '飯田 チアリ',
                "faculty": "文教育学部 \n人間社会科学科",
                "course": "",
                "grade": "4年",
                "hometown": "秋田県",
                "motto": "明朗快活",
                twitter: "mizucon22_01",
                instagram: "mizucon22_01",
                interview1: "「ULU」というコンセプトと共に、新しいミスコン作りを当事者として進めて行きたいと思ったから。",
                interview2: "志の高い同性が多い！勉強でもサークル活動でも良い刺激を貰うことが出来る！。",
                interview3: "「SKETDANCE」誰かのために本気になることの素晴らしさを教えてくれたから",
                interview4: "Cheerfulな性格",
                interview5: "当たり前のことを当たり前と考えないようになってきた現代、しかし今までの当たり前が否定される必要があるのか疑問な時があります。今まで持っていた価値観の意味を常に問い続け、自分らしさを私自身、そして皆様自身見つけられるコンテストにしたいです！",
                interview6: "自分らしい美しさを見つけたい！"
            },
            {
                id: 2,
                entry_id: 2,
                block: 'A',
                stage: '1',
                name: '坂口 真依子',
                "faculty": "文教育学部 \n人間社会科学科",
                "course": "",
                "grade": "2年",
                "hometown": "北海道",
                "motto": "勇気は一瞬、後悔は一生",
                twitter: "mizucon22_02",
                instagram: "mizucon22_02",
                interview1: "怪我をして困っていた時に、昨年のグランプリの方に助けて頂いて影響を受けた。多くの人に勇気や元気を届けたいと思ったから！",
                interview2: "アットホームでみんなの距離が近いところ。尊敬できる人（大好きな人）がたくさんいるところ。",
                interview3: "ブッタとシッタカブッタ　悩んでいる時に読むと、とても心が軽くなる一冊です！",
                interview4: "行動力とポジティブさ！",
                interview5: "何か行動を起こすきっかけになれたら嬉しいです！お茶大のこともたくさん知ってもらいたいです！",
                interview6: "様々な人と交流することで多角的に考えられる人間になりたいです。人を大切にしながら、挑戦・変化し続ける自分でありたいと思います。好きなことも極めていきたいです！"
            },
            {
                id: 3,
                entry_id: 3,
                block: 'A',
                stage: '1',
                name: '三月 しょう',
                "faculty": "文教育学部 \n言語文化学科",
                "course": "",
                "grade": "4年",
                "hometown": "中国 山東省",
                "motto": "迷ったら後悔をしない選択肢で！",
                twitter: "mizucon22_03",
                instagram: "mizucon22_03",
                interview1: "①自身の経験や考えを発信し、目標に向かって努力している人々を応援したい!\n②3年間徽音祭の運営に携わったことから、留学生ファイナリストとしてミスコンの多様性に貢献して学園祭を盛り上げたい!",
                interview2: "少数精鋭、先生や先輩と距離が近い、勉強に集中できる環境、清潔な校内",
                interview3: "『佐賀のがばいばあちゃん』です!自身がおばあちゃん育ちであることと、幼い頃4年間佐賀県に住んだ経験があるため、大変共感できました。",
                interview4: "現状をより良くしようとする向上心と行動力、楽しみながら色々なことに挑戦すること、そして笑った時の目元です!",
                interview5: "自身の頑張っている姿を通して、皆様を元気付ける、勇気付ける存在になりたいです!理想の自分に向かって努力している方、4ヶ月ではありますが、一緒に頑張りましょう💚",
                interview6: "自身の「容姿、発信力、行動力」方面の成長を発信し、より好きな自分で、目標に向かった努力している方々を応援したいです!"
            },
            {
                id: 4,
                entry_id: 4,
                block: 'A',
                stage: '1',
                name: '門上 華子',
                "faculty": "文教育学部 \n芸術表現行動学科",
                "course": "",
                "grade": "3年",
                "hometown": "兵庫県西宮市",
                "motto": "やらない後悔よりやる後悔",
                twitter: "mizucon22_04",
                instagram: "mizucon22_04",
                interview1: "2年前の水コンの司会をしたから",
                interview2: "人がみんなとても優しい",
                interview3: "「君の膵臓をたべたい」\n主人公の儚さがやみつきになります。",
                interview4: "とても明るいです!",
                interview5: "お茶大や音楽科の魅力を知ってもらえればと思います!4ヶ月間よろしくお願いします!",
                interview6: "見た目も中身も素敵な女性になりたいです。"
            },
        ];
    },
});
