import React, { useEffect } from 'react';
import { useInView } from 'react-hook-inview';

interface TwitterProps {
    user: string | undefined
}
export const Twitter: React.FC<TwitterProps> = ({user}: TwitterProps) => {
    const [ref, isVisible] = useInView({
        threshold: 1,
        unobserveOnEnter: true,
    });
    useEffect(() => {
        if ((window as any).twttr) {
            (window as any).twttr.widgets.load();
        }
    }, [isVisible]);
    return (
        <div className="card as-twitter">
            <div className="card-content" ref={ref}>
                {isVisible && (
                    <a
                        className="twitter-timeline"
                        data-chrome="nofooter noborders"
                        data-lang="en"
                        href={`https://twitter.com/${user ?? "mizucon_2022"}?ref_src=twsrc%5Etfw`}
                    >
                        @{`${user ?? "mizucon_2022"}`}のTweetを読み込んでいます...
                    </a>
                )}
            </div>
        </div>
    );
};